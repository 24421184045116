<template>
	<div class="grid crud-demo">
		<div class="col-12">
			<div class="card">
				<Toast/>
				<Toolbar class="mb-4">
					<template v-slot:start>
						<div class="my-2">
							<Button label="Neu" icon="pi pi-plus" class="p-button-success mr-2" @click="openNew" />
							<Button label="Löschen" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected" :disabled="!selectedMitarbeiter || !selectedMitarbeiter.length" />
						</div>
					</template>

					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportCSV($event)"  />
					</template>
				</Toolbar>

				<DataTable  ref="dt" :value="mitarbeiter" v-model:selection="selectedMitarbeiter" dataKey="ID" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="{first} bis {last} von {totalRecords} Mitarbeiter" responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Mitarbeiter</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Suchen..." />
                            </span>
						</div>
					</template>

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="ID" header="ID" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">ID</span>
							{{slotProps.data.ID}}
						</template>
					</Column>
					<Column field="ANREDE" header="Anrede" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Anrede</span>
							{{slotProps.data.ANREDE}}
						</template>
					</Column>
					<Column field="VORNAME" header="Vorname" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Vorname</span>
							{{slotProps.data.VORNAME}}
						</template>
					</Column>
					<Column field="NACHNAME" header="Nachname" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Nachname</span>
							{{slotProps.data.NACHNAME}}
						</template>
					</Column>
          <Column field="email" header="Email" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Email</span>
              {{slotProps.data.email}}
            </template>
          </Column>
					<Column field="ROLLE" header="Rolle" :sortable="true">
						<template #body="slotProps">
							<span class="p-column-title">Rolle</span>
							{{slotProps.data.ROLLE}}
						</template>
					</Column>
          <Column field="UNTERNEHMEN" header="Unternehmen" :sortable="true">
            <template #body="slotProps">
              <span class="p-column-title">Unternehmen</span>
              {{slotProps.data.UNTERNEHMEN}}
            </template>
          </Column>
					<Column>
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editMitarbeiter(slotProps.data)" />
							<Button icon="pi pi-trash" class="p-button-rounded p-button-warning" @click="confirmDeleteMitarbeiter(slotProps.data)" />
						</template>
					</Column>
				</DataTable>

				<Dialog  v-model:visible="mitarbeiterDialog" :style="{width: '450px'}" header="Mitarbeiter Details" :modal="true" class="p-fluid">
					<div class="field">
						<label for="ANREDE">Anrede</label>
						<InputText id="anrede" v-model.trim="mitarbeiterObj.ANREDE" required="true" autofocus :class="{'p-invalid': submitted && !mitarbeiterObj.ANREDE}" />
						<small class="p-invalid" v-if="submitted && !mitarbeiterObj.ANREDE">Anrede muss angegeben werden.</small>
					</div>
          <div class="field">
            <label for="VORNAME">Vorname</label>
            <InputText id="vorname" v-model.trim="mitarbeiterObj.VORNAME" required="true" autofocus :class="{'p-invalid': submitted && !mitarbeiterObj.VORNAME}" />
            <small class="p-invalid" v-if="submitted && !mitarbeiterObj.VORNAME">Vorname muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="NACHNAME">Nachname</label>
            <InputText id="nachname" v-model.trim="mitarbeiterObj.NACHNAME" required="true" autofocus :class="{'p-invalid': submitted && !mitarbeiterObj.NACHNAME}" />
            <small class="p-invalid" v-if="submitted && !mitarbeiterObj.NACHNAME">Nachname muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="email">Email</label>
            <InputText id="email" v-model.trim="mitarbeiterObj.email" required="true" autofocus :class="{'p-invalid': submitted && !mitarbeiterObj.email}" />
            <small class="p-invalid" v-if="submitted && !mitarbeiterObj.email">Email muss angegeben werden.</small>
          </div>
          <div class="field">
            <label for="ROLLE" class="mb-3">Rolle</label>
            <Dropdown id="rolle" v-model="mitarbeiterObj.ROLLE" :options="rollen"  placeholder="Rolle">
<!--              <template #value="slotProps">-->
<!--                <div v-if="slotProps.value">-->
<!--                  <span :class="'product-badge status-' +slotProps.value">{{slotProps.value}}</span>-->
<!--                </div>-->
<!--                <span v-else>-->
<!--									{{slotProps.placeholder}}-->
<!--								</span>-->
<!--              </template>-->
            </Dropdown>
          </div>
					<div class="field">
						<label for="FK_ID_UNTERNEHMEN" class="mb-3">Unternehmens ID</label>
						<Dropdown id="fk_id_unternehmen" v-model="mitarbeiterObj.FK_ID_UNTERNEHMEN" :options="unternehmenCustom"  placeholder="Unternehmen">
							<template #value="slotProps">
								<div v-if="slotProps.value">
									<span :class="'product-badge status-' +slotProps.value">{{slotProps.value}}</span>
								</div>
								<span v-else>
									{{slotProps.placeholder}}
								</span>
							</template>
						</Dropdown>
					</div>
					<template #footer>
						<Button label="Abbrechen" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
						<Button label="Speichern" icon="pi pi-check" class="p-button-text" @click="saveMitarbeiter" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMitarbeiterDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mitarbeiterObj"><b>{{mitarbeiterObj.NAME}}</b> wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMitarbeiterDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteMitarbeiter" />
					</template>
				</Dialog>

				<Dialog v-model:visible="deleteMitarbeitersDialog" :style="{width: '450px'}" header="Achtung!" :modal="true">
					<div class="flex align-items-center justify-content-center">
						<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
						<span v-if="mitarbeiterObj">Ausgewählte Mitarbeiter wirklich löschen?</span>
					</div>
					<template #footer>
						<Button label="Nein" icon="pi pi-times" class="p-button-text" @click="deleteMitarbeitersDialog = false"/>
						<Button label="Ja" icon="pi pi-check" class="p-button-text" @click="deleteSelectedMitarbeiter" />
					</template>
				</Dialog>
			</div>
		</div>
	</div>

</template>

<script>
import {FilterMatchMode} from 'primevue/api';
import DataService from "@/service/DataService";

export default {
	data() {
		return {
      unternehmen: null,
      unternehmenCustom: [],
			rollen: [],
      mitarbeiter: null,
      mitarbeiterDialog: false,
      deleteMitarbeiterDialog: false,
      deleteMitarbeitersDialog: false,
      neu: false,
      mitarbeiterObj: {},
      selectedMitarbeiter: null,
			filters: {},
			submitted: false,
		}
	},
  dataService: null,
	created() {
    this.dataService = new DataService();
		this.initFilters();
	},
	async mounted() {
    this.unternehmen = await this.dataService.getUnternehmen();
		this.rollen = ['Techniker', 'Standard'];

    this.unternehmen.forEach((el) => {
      let string = `${el.ID} ${el.NAME}`;
      this.unternehmenCustom.push(string);
    })

    this.mitarbeiter = await this.dataService.getMitarbeiter();
	},
  watch: {
    mitarbeiter: function (value){
      console.log(value)
    }
  },
	methods: {
		openNew() {
      this.mitarbeiterObj = {};
      this.submitted = false;
      this.mitarbeiterDialog = true;
      this.neu = true;
		},
		hideDialog() {
			this.mitarbeiterDialog = false;
			this.submitted = false;
      this.neu = false;
		},
    async saveMitarbeiter(){
      this.submitted = true;

      console.log(this.neu)
      if(this.neu){
				console.log(this.mitarbeiterObj)
          await this.dataService.saveMitarbeiter(this.mitarbeiterObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Mitarbeiter erstellt', life: 3000});
          // this.mitarbeiter = await this.dataService.getMitarbeiter();
          setTimeout(async()=> {this.mitarbeiter = await this.dataService.getMitarbeiter()}, 100);
          this.unternehmen = await this.dataService.getUnternehmen();
          this.mitarbeiterDialog = false;
          this.mitarbeiterObj = {};
      } else {
          await this.dataService.updateMitarbeiter(this.mitarbeiterObj);
          this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Mitarbeiter aktualisiert', life: 3000});
          setTimeout(async()=> {this.mitarbeiter = await this.dataService.getMitarbeiter()}, 100);

        this.unternehmen = await this.dataService.getUnternehmen();
          this.mitarbeiterDialog = false;
          this.mitarbeiterObj = {};
      }
      this.neu = false;

    },
    editMitarbeiter(mitarbeiter) {
      this.mitarbeiterObj = {...mitarbeiter};
      this.mitarbeiterDialog = true;
      this.neu = false;
    },
    confirmDeleteMitarbeiter(mitarbeiter) {
      this.mitarbeiterObj = mitarbeiter;
      this.deleteMitarbeiterDialog = true;
    },
    async deleteMitarbeiter() {
      let data = [];
      data.push(this.mitarbeiterObj);
      await this.dataService.deleteMitarbeiter(data);

      this.deleteMitarbeiterDialog = false;
      this.mitarbeiterObj = {};
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Mitarbeiter gelöscht', life: 3000});
      setTimeout(async()=> {this.mitarbeiter = await this.dataService.getMitarbeiter()}, 100);

    },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		confirmDeleteSelected() {
			this.deleteMitarbeitersDialog = true;
		},
    async deleteSelectedMitarbeiter() {
      this.deleteMitarbeitersDialog = false;
      await this.dataService.deleteMitarbeiter(this.selectedMitarbeiter);
      this.selectedMitarbeiter = null;
      this.$toast.add({severity:'success', summary: 'Erfolgreich', detail: 'Mitarbeiter gelöscht', life: 3000});
      setTimeout(async()=> {this.mitarbeiter = await this.dataService.getMitarbeiter()}, 100);

    },
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
            }
        }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/demo/styles/badges.scss';
</style>
